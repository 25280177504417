import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { getToken, camelize } from '_/utils/token'

import { Controller, FormProvider, useForm } from 'react-hook-form'

import * as Modal from '_components/modal/generic-modal'
import { Select, Checkbox } from '_/components/inputs'
import { DatePicker, Loader } from '@refera/ui-web'
import { InputLabel, IconButton, InputAdornment } from '@material-ui/core'

import useStyles from './styles'
import {
  castToStringifiedNumber,
  CONDOLIVRE_STATUS_OPTIONS,
  CONTRACT_TYPE_OPTIONS,
  LEVEL_OPTIONS,
  PROVIDER_REFERA_DETAILS_FIELDS,
  transformValue,
} from './constants'
import { FormHelperText, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useToast } from '_/hooks/use-toast'
import { getContractOptionsSelector } from '_/modules/company/selector'
import { getConnectionSelector, providerSelector } from '_modules/provider/selectors'
import moment from 'moment'
import { getContractOptions } from '_/modules/company/actions'
import { getConnetionMessageTool, getProvider } from '_/modules/provider/actions'
import ClearIcon from '@material-ui/icons/Clear'
import axios from 'axios'
import { API_URL } from '_/config/environment'
import { useParams } from '@reach/router'
import { decamelizeKeys } from 'humps'

const referaDetailsFieldTypes = Object.entries(PROVIDER_REFERA_DETAILS_FIELDS).reduce(
  (acc, [, value]) => {
    acc[value.name] = value.type
    return acc
  },
  {}
)

const transformPayloadValues = data => {
  const transformedData = Object.entries(data).reduce((acc, [key, value]) => {
    switch (referaDetailsFieldTypes[key]) {
      case 'number': {
        acc[key] = value ? castToStringifiedNumber(value) : null
        break
      }
      case 'datetime': {
        try {
          if (!value) {
            acc[key] = null
            break
          }

          const formattedDate = moment(value).toISOString()
          acc[key] = formattedDate
        } catch (error) {
          throw new Error('Data inválida')
        }
        break
      }
      case 'date': {
        try {
          if (!value) {
            acc[key] = null
            break
          }
          const formattedDate = moment(value).format('YYYY-MM-DD')
          acc[key] = formattedDate
        } catch (error) {
          throw new Error('Data inválida')
        }
        break
      }
      default: {
        acc[key] = value
        break
      }
    }

    return acc
  }, {})
  return transformedData
}

export function ProviderReferaDetailsModal({ onClose, open }) {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { showToast } = useToast()
  const contractStatusOptions = useSelector(getContractOptionsSelector)
  const connection = useSelector(getConnectionSelector)
  const provider = useSelector(providerSelector)
  const { providerId } = useParams()

  const [loading, setLoading] = useState(false)
  const [onboardingOptions, setOnboardingOptions] = useState([])

  const renderInput = useCallback(params => <TextField variant="standard" {...params} />, [])

  const defaultValues = useMemo(() => {
    if (!provider) {
      return {}
    }
    const filteredValues = Object.entries(PROVIDER_REFERA_DETAILS_FIELDS).reduce(
      (acc, [key, value]) => {
        acc[value.name] = transformValue(provider[value.name], key)
        return acc
      },
      {}
    )
    return filteredValues
  }, [provider])

  const methods = useForm({
    mode: 'all',
  })

  const { handleSubmit } = methods

  const onSubmit = async data => {
    setLoading(true)

    let transformedPayload

    try {
      transformedPayload = transformPayloadValues(data)
    } catch (error) {
      showToast({
        type: 'error',
      })
      setLoading(false)
      return
    }

    if (transformedPayload[PROVIDER_REFERA_DETAILS_FIELDS.BLOCKED_CONTRACT_DATE.name] !== null) {
      transformedPayload[PROVIDER_REFERA_DETAILS_FIELDS.BLOCKED_CONTRACT_DATE.name] = moment(
        transformedPayload[PROVIDER_REFERA_DETAILS_FIELDS.BLOCKED_CONTRACT_DATE.name],
        'YYYY-MM-DD'
      ).toISOString()
    }

    try {
      const headers = getToken()

      await axios.patch(
        `${API_URL}/company/${providerId}/`,
        decamelizeKeys(transformedPayload),
        headers
      )

      dispatch(getProvider(providerId))

      showToast({
        type: 'success',
      })
      setLoading(false)
      onClose()
    } catch (error) {
      showToast({
        type: 'error',
      })
      setLoading(false)
    }
  }

  const whatsappConnectionOptions = useMemo(
    () =>
      connection
        ?.filter(item => item.purposeId === 1)
        ?.slice()
        ?.sort((a, b) => a.id - b.id) || [],
    [connection]
  )

  const budgeteerConnectionOptions = useMemo(
    () =>
      connection
        ?.filter(item => item.purposeId === 3)
        ?.slice()
        ?.sort((a, b) => a.id - b.id) || [],
    [connection]
  )

  const renderCondolivreStatusClearButton = useCallback(() => {
    if (!methods.getValues(PROVIDER_REFERA_DETAILS_FIELDS.CONDOLIVRE_STATUS.name)) return null

    return (
      <InputAdornment position="end">
        <IconButton
          onClick={() =>
            methods.setValue(PROVIDER_REFERA_DETAILS_FIELDS.CONDOLIVRE_STATUS.name, null)
          }
          style={{ padding: '4px', marginRight: '20px' }}
        >
          <ClearIcon
            fontSize="small"
            style={{
              fill: '#B4B4B4',
            }}
          />
        </IconButton>
      </InputAdornment>
    )
  }, [methods.watch(PROVIDER_REFERA_DETAILS_FIELDS.CONDOLIVRE_STATUS.name)])

  const renderWhatsappConnectionClearButton = useCallback(() => {
    if (!methods.getValues(PROVIDER_REFERA_DETAILS_FIELDS.WHATSAPP_CONNECTION.name)) return null

    return (
      <InputAdornment position="end">
        <IconButton
          onClick={() =>
            methods.setValue(PROVIDER_REFERA_DETAILS_FIELDS.WHATSAPP_CONNECTION.name, null)
          }
          style={{ padding: '4px', marginRight: '20px' }}
        >
          <ClearIcon
            fontSize="small"
            style={{
              fill: '#B4B4B4',
            }}
          />
        </IconButton>
      </InputAdornment>
    )
  }, [methods.watch(PROVIDER_REFERA_DETAILS_FIELDS.WHATSAPP_CONNECTION.name)])

  const fetchSelectFieldsOptions = useCallback(async () => {
    setLoading(true)

    const headers = getToken()

    await Promise.all([
      dispatch(getContractOptions()),
      dispatch(
        getConnetionMessageTool({
          type_name: 'whatsapp',
        })
      ),
      axios.get(`${API_URL}/serviceprovider/get-options`, headers),
    ])
      .then(([, , res]) => {
        setOnboardingOptions(camelize(res.data))
        setLoading(false)
      })
      .catch(() => {
        showToast({
          type: 'error',
        })
        setLoading(false)
      })
  }, [dispatch, setLoading, setOnboardingOptions])

  useEffect(() => {
    fetchSelectFieldsOptions()
  }, [])

  return (
    <Modal.Root open={open} onClose={onClose} maxWidth="md">
      <Modal.TitleModal title="Dados da Refera" />

      <Modal.Content className={styles.modalContent}>
        <FormProvider {...methods}>
          <form className={styles.form} onSubmit={e => e.preventDefault()}>
            <div className={styles.row}>
              <Checkbox
                label="Atende a Refera"
                name={PROVIDER_REFERA_DETAILS_FIELDS.PROVIDES_REFERA.name}
                defaultValue={defaultValues[PROVIDER_REFERA_DETAILS_FIELDS.PROVIDES_REFERA.name]}
                containerClasses={styles.checkboxContainer}
              />

              <Select
                label="Nível"
                name={PROVIDER_REFERA_DETAILS_FIELDS.LEVEL.name}
                options={LEVEL_OPTIONS}
                getLabel={item => item.label}
                getValue={item => item.value}
                defaultValue={defaultValues[PROVIDER_REFERA_DETAILS_FIELDS.LEVEL.name]}
              />

              <Select
                label="Etapa"
                name={PROVIDER_REFERA_DETAILS_FIELDS.ONBOARDING_STAGE.name}
                options={onboardingOptions ?? []}
                getLabel={item => item.option}
                getValue={item => item.status}
                defaultValue={defaultValues[PROVIDER_REFERA_DETAILS_FIELDS.ONBOARDING_STAGE.name]}
              />
            </div>

            <div className={styles.row} style={{ paddingTop: '18px' }}>
              <Checkbox
                label="Bloquear repasse financeiro"
                name={PROVIDER_REFERA_DETAILS_FIELDS.BLOCK_FINANCE_TRANSFERS.name}
                defaultValue={
                  defaultValues[PROVIDER_REFERA_DETAILS_FIELDS.BLOCK_FINANCE_TRANSFERS.name]
                }
                containerClasses={styles.checkboxContainer}
              />
              <Select
                label="Tipo de contrato"
                name={PROVIDER_REFERA_DETAILS_FIELDS.CONTRACT_TYPE.name}
                defaultValue={
                  defaultValues[PROVIDER_REFERA_DETAILS_FIELDS.CONTRACT_TYPE.name] ?? null
                }
                getLabel={item => item.label}
                getValue={item => item.value}
                required
                options={CONTRACT_TYPE_OPTIONS}
              />

              <Select
                label="Status contrato"
                name={PROVIDER_REFERA_DETAILS_FIELDS.CONTRACT_STATUS.name}
                defaultValue={
                  defaultValues[PROVIDER_REFERA_DETAILS_FIELDS.CONTRACT_STATUS.name] ?? null
                }
                getLabel={item => item.label}
                getValue={item => item.value}
                options={contractStatusOptions ?? []}
              />
            </div>

            <div className={styles.row}>
              <Select
                label="Status Condolivre"
                name={PROVIDER_REFERA_DETAILS_FIELDS.CONDOLIVRE_STATUS.name}
                defaultValue={
                  defaultValues[PROVIDER_REFERA_DETAILS_FIELDS.CONDOLIVRE_STATUS.name] ?? null
                }
                getLabel={item => item.label}
                getValue={item => item.value}
                endAdornment={renderCondolivreStatusClearButton()}
                options={CONDOLIVRE_STATUS_OPTIONS}
                containerClasses={styles.condolivreStatusContainer}
                labelClasses={styles.condolivreStatusLabel}
              />
              <div className={styles.datePickerInputGroup}>
                <InputLabel
                  variant="standard"
                  id={`${PROVIDER_REFERA_DETAILS_FIELDS.GENERATE_CONTRACT_DATE.name}-label`}
                  shrink
                  className={styles.datePickerLabel}
                >
                  Contrato gerado
                </InputLabel>
                <Controller
                  control={methods.control}
                  name={PROVIDER_REFERA_DETAILS_FIELDS.GENERATE_CONTRACT_DATE.name}
                  defaultValue={
                    defaultValues[PROVIDER_REFERA_DETAILS_FIELDS.GENERATE_CONTRACT_DATE.name] ??
                    null
                  }
                  render={field => {
                    return (
                      <DatePicker
                        variant="inline"
                        mask="__/__/____"
                        format="dd/MM/yyyy"
                        refuse={/[^\d.]+/gi}
                        renderInput={renderInput}
                        disabled
                        {...field}
                      />
                    )
                  }}
                />

                <FormHelperText
                  className={styles.errorMessage}
                  error={
                    !!methods.errors?.[PROVIDER_REFERA_DETAILS_FIELDS.GENERATE_CONTRACT_DATE.name]
                  }
                >
                  {
                    methods.errors?.[PROVIDER_REFERA_DETAILS_FIELDS.GENERATE_CONTRACT_DATE.name]
                      ?.message
                  }
                </FormHelperText>
              </div>

              <div className={styles.datePickerInputGroup}>
                <InputLabel
                  variant="standard"
                  id={`${PROVIDER_REFERA_DETAILS_FIELDS.BLOCKED_CONTRACT_DATE.name}-label`}
                  shrink
                  className={styles.datePickerLabel}
                >
                  Data de bloqueio por contrato
                </InputLabel>
                <Controller
                  control={methods.control}
                  name={PROVIDER_REFERA_DETAILS_FIELDS.BLOCKED_CONTRACT_DATE.name}
                  defaultValue={
                    defaultValues[PROVIDER_REFERA_DETAILS_FIELDS.BLOCKED_CONTRACT_DATE.name] ?? null
                  }
                  render={field => {
                    return (
                      <DatePicker
                        variant="inline"
                        mask="__/__/____"
                        format="dd/MM/yyyy"
                        refuse={/[^\d.]+/gi}
                        renderInput={renderInput}
                        {...field}
                      />
                    )
                  }}
                />

                <FormHelperText
                  className={styles.errorMessage}
                  error={
                    !!methods.errors?.[PROVIDER_REFERA_DETAILS_FIELDS.BLOCKED_CONTRACT_DATE.name]
                  }
                >
                  {
                    methods.errors?.[PROVIDER_REFERA_DETAILS_FIELDS.BLOCKED_CONTRACT_DATE.name]
                      ?.message
                  }
                </FormHelperText>
              </div>
            </div>

            <div className={styles.row} style={{ paddingTop: '21px' }}>
              <Select
                label="Conexão WhatsApp"
                name={PROVIDER_REFERA_DETAILS_FIELDS.WHATSAPP_CONNECTION.name}
                defaultValue={
                  defaultValues[PROVIDER_REFERA_DETAILS_FIELDS.WHATSAPP_CONNECTION.name] ?? null
                }
                getLabel={item => item.desc}
                getValue={item => item.id}
                options={whatsappConnectionOptions}
                endAdornment={renderWhatsappConnectionClearButton()}
              />
              {provider?.providesRefera && (
                <Select
                  label="WhatsApp Orçamentista"
                  name={PROVIDER_REFERA_DETAILS_FIELDS.BUDGETEER_WHATSAPP.name}
                  defaultValue={
                    defaultValues[PROVIDER_REFERA_DETAILS_FIELDS.BUDGETEER_WHATSAPP.name] ?? null
                  }
                  getLabel={item => item.desc}
                  getValue={item => item.id}
                  options={budgeteerConnectionOptions}
                />
              )}
            </div>
          </form>
        </FormProvider>
      </Modal.Content>

      <Modal.Actions>
        <Modal.ButtonRed onClick={onClose} disabled={loading}>
          Cancelar
        </Modal.ButtonRed>
        <Modal.ButtonFullBlue onClick={handleSubmit(onSubmit)} disabled={loading}>
          Salvar
        </Modal.ButtonFullBlue>
      </Modal.Actions>
      <Loader hasBackdrop open={loading} />
    </Modal.Root>
  )
}
